<template>
  <v-data-table :headers="headers" :items="vatPercentages" class="elevation-1">
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title v-t="'vatPercentage'"></v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">{{ $t('newParam', {param:$t('vat')}) }}</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="4">
                    <v-text-field v-model="editedItem.code" :label="$t('code')"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <v-text-field v-model="editedItem.description" :label="$t('description')"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <v-text-field v-model="editedItem.percentage" :label="$t('percentage')"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close" v-t="'cancel'"></v-btn>
              <v-btn color="blue darken-1" text @click="save" v-t="'confirm'"></v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">{{ $t('deleteText', {param:$t('vatPercentage')}) }}</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete" v-t="'cancel'"></v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm" v-t="'confirm'"></v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    
    <template v-slot:item.percentage="{ item }">
        {{ `${item.percentage !== null? item.percentage.toFixed(2) : "0"} %` }}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
      <!-- <v-icon small @click="deleteItem(item)">mdi-delete</v-icon> -->
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize" v-t="'reset'"></v-btn>
    </template>
  </v-data-table>
</template>
<script>
    import { get, call, sync } from 'vuex-pathify';
    export default {
        data: () => ({
            dialog: false,
            dialogDelete: false,
            editedItem: { code: '', description: '', percentage: 0 },
            defaultItem: { code: '', description: '', percentage: 0 },
        }),
        computed: {
            vatPercentages: sync("vats/vatPercentages"),
            formTitle () { return !!this.editedItem?.id ? this.$t('newParam', {param:this.$t('vat')}) : this.$t('editParam', {param:this.$t('vat')}) },
            headers(){
              return [
                  { text: this.$t('code'), value: 'code' },
                  { text: this.$t('description'), value: 'description' },
                  { text: this.$t('percentage'), value: 'percentage' },
                  { text: this.$t('actions'), value: 'actions', sortable: false },
              ]
            }
        },
        watch: {
            dialog (val) { 
              val || this.close() 
            },
            dialogDelete (val) { 
              val || this.closeDelete() 
            },
        },
        created () { this.initialize() },
        methods: {
            initialize () {
                this.initVatCollection();
            },
            editItem (item) {
                this.editedItem = Object.assign({}, item);
                this.dialog = true
            },
            deleteItem (item) {
                this.editedItem = Object.assign({}, item);
                this.dialogDelete = true
            },
            deleteItemConfirm () {
                this.apiVatPercentageIdDelete(this.editItem.id);
                this.closeDelete()
            },
            close () {
                this.dialog = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                })
            },
            closeDelete () {
                this.dialogDelete = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                })
            },
            save () {
                !this.editedItem.id?
                    this.apiVatPercentagePost(this.editedItem)
                    : this.apiVatPercentageIdPut(this.editedItem);
                this.close()
            },
            ...call("vats/*"),
        },
    }
</script>
